import { useState } from "react";
import styled from "styled-components";

import * as auth from "./auth";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";

const StyledFormContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFormWrapper = styled.div`
  width: 300px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledParagraph = styled.p`
  text-align: center;
  margin-bottom: 40px;
`;

const StyledPassword = styled(Password)`
  margin-bottom: 15px;
  input{
    width: 100%;
  }
`;

const StyledInput = styled(InputText)`
  margin-bottom: 15px;
`;

const StyledHeader = styled.h1`
  text-align: center;
`;

const LoginPage = ({ setIsAuthenticated, history }) => {
  const [userLogin, setUserLogin] = useState("Synapsi");
  const [password, setPassword] = useState("xyz");
  const url = "/cipher";

  const handleSubmit = (e) => {
    e.preventDefault();
    auth.authenticate(userLogin, password, url, setIsAuthenticated, history);
  };

  return (
    <StyledFormContainer>
      <StyledFormWrapper>
        <StyledHeader>Witaj w szyferze</StyledHeader>
        <StyledParagraph>Proszę zaloguj się</StyledParagraph>
        <StyledForm onSubmit={handleSubmit}>
          <StyledInput
            required
            placeholder="Login"
            value={userLogin}
            onChange={(e) => {
              setUserLogin(e.target.value);
            }}
          />
          <StyledPassword
            required
            toggleMask
            feedback={false}
            placeholder="Hasło"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Button label="Zaloguj" />
        </StyledForm>
      </StyledFormWrapper>
    </StyledFormContainer>
  );
};

export default LoginPage;
