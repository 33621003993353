import { useState } from "react";
import styled from "styled-components";

import * as auth from "./auth";
import { encode, decode } from "./cipher";

import { Button } from "primereact/button";
import CodeContainer from "./CodeContainer";

const CipherContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHeader = styled.h1`
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin: 15px 0;
`;

const CipherPage = ({ setIsAuthenticated, history }) => {
  const [textToCipher, setTextToCipher] = useState("");
  const [keyForCipher, setKeyForCipher] = useState("");
  const [cipheredText, setCipheredText] = useState("");
  const [textToDecipher, setTextToDecipher] = useState("");
  const [keyForDecipher, setKeyForDecipher] = useState("");
  const [decipheredText, setDecipheredText] = useState("");

  const handleSubmitEncode = (e) => {
    e.preventDefault();
    const encoded = encode(textToCipher, keyForCipher);
    setCipheredText(encoded);
  };

  const handleSubmitDecode = (e) => {
    e.preventDefault();
    const decoded = decode(textToDecipher, keyForDecipher);
    setDecipheredText(decoded);
  };

  return (
    <CipherContainer>
      <StyledHeader>Maszyna szyfrująca</StyledHeader>
      <CodeContainer
        textareaLabel={"Wpisz tekst do zaszyfrowania"}
        inputLabel={"Podaj klucz szyfrujący"}
        textareaValue={textToCipher}
        textareaHandler={setTextToCipher}
        inputValue={keyForCipher}
        inputHandler={setKeyForCipher}
        submitHandler={handleSubmitEncode}
        resultValue={cipheredText}
        title="Szyfrowanie tekstu"
        buttonText="zaszyfruj"
      />
      <CodeContainer
        textareaLabel={"Wpisz tekst do rozszyfrowania"}
        inputLabel={"Podaj klucz rozszyfrowujący"}
        textareaValue={textToDecipher}
        textareaHandler={setTextToDecipher}
        inputValue={keyForDecipher}
        inputHandler={setKeyForDecipher}
        submitHandler={handleSubmitDecode}
        resultValue={decipheredText}
        title="Rozszyfrowywanie tekstu"
        autoResize
        buttonText="rozszyfruj"
      />
      <StyledButton
        label="Wyloguj"
        onClick={() => auth.logout("/", setIsAuthenticated, history)}
      />
    </CipherContainer>
  );
};

export default CipherPage;
