import styled from "styled-components";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

const StyledLabel = styled.label`
  width: 100%;
`;

const StyledInput = styled(InputText)`
  width: 100%;
  margin-top: 10px;
`;

const StyledTextarea = styled(InputTextarea)`
  width: 100%;
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
`;

const CodeContainer = ({
  textareaLabel,
  inputLabel,
  textareaValue,
  textareaHandler,
  inputValue,
  inputHandler,
  submitHandler,
  resultValue,
  title,
  autoResize,
  buttonText
}) => {
  return (
    <>
      <h2>{title}</h2>
      <StyledForm onSubmit={submitHandler}>
        <StyledLabel>
          {textareaLabel}
          <StyledTextarea
            required
            rows={8}
            value={textareaValue}
            onChange={(e) => {
              textareaHandler(e.target.value);
            }}
          />
        </StyledLabel>
        <StyledLabel>
          {inputLabel}
          <StyledInput
            required
            value={inputValue}
            onChange={(e) => {
              inputHandler(e.target.value);
            }}
          />
        </StyledLabel>
        <StyledButton label={buttonText} />
        <StyledTextarea
          disabled
          autoResize={autoResize ? true : false}
          value={resultValue}
        />
      </StyledForm>
    </>
  );
};

export default CodeContainer;
