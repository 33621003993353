import { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import LoginPage from "./client src/LoginPage";
import PrivateRoute from "./client src/PrivateRoute";
import CipherPage from "./client src/CipherPage";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <LoginPage
              isAuthenticated={isAuthenticated}
              setIsAuthenticated={setIsAuthenticated}
              history={props.history}
            />
          )}
        />
        <PrivateRoute
          isAuthenticated={isAuthenticated}
          path="/cipher"
          render={() => <CipherPage setIsAuthenticated={setIsAuthenticated} />}
        />
        <Route path="*" render={() => "404"} />
      </Switch>
    </Router>
  );
};

export default App;
