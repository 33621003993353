import md5 from "md5";
import users from "./users";

const login = (url, setIsAuthenticated, history) => {
  setIsAuthenticated(true);
  history.push(url);
};

const logout = (url, setIsAuthenticated, history) => {
  setIsAuthenticated(false);
  history.push(url);
};

const authenticate = (userLogin, password, url, setIsAuthenticated, history) => {
  const authenticate = users.find(
    (user) => user.hashedPassword === md5(password) && user.login === userLogin
  );
  if (authenticate) {
    login(url, setIsAuthenticated, history);
  }
};

export { login, logout, authenticate };
