const chunkArr = (array, elementsPerChunk) => {
  const result = array.reduce((resultArr, item, index) => {
    const chunkIndex = Math.floor(index / elementsPerChunk);
    if (!resultArr[chunkIndex]) {
      resultArr[chunkIndex] = [];
    }
    resultArr[chunkIndex].push(item);
    return resultArr;
  }, []);
  return result;
};

const convertDecToHex = (decimal) => {
  return decimal.toString(16);
};

const convertHexToDec = (hexadecimal) => {
  return parseInt(hexadecimal, 16);
};

const charsArrToHexArr = (array) => {
  return array.map((char) => convertDecToHex(char));
};

const convertCharToCode = (character) => {
  return character.charCodeAt();
};

const convertCodeToChar = (character) => {
  return String.fromCharCode(character);
};

const convertEachHexElementToDec = (array) => {
  return array.map((element) => convertHexToDec(element));
};

const textStringToCharsArr = (string) => {
  const textCharsArr = string.split("");
  return textCharsArr.map((character) => convertCharToCode(character));
};

const equalizeLengthOfElements = (array, size) => {
  const result = array.map((element) => {
    let s = element + "";
    while (s.length < size) s = "0" + s;
    return s;
  });
  return result;
};

const multiplyChunk = (array, multiplicandsArray) => {
  return array.map((element, index) => element * multiplicandsArray[index]);
};

const cipherChunk = (array, keyArr) => {
  const multipliedChunk = multiplyChunk(array, keyArr);
  const hexArr = charsArrToHexArr(multipliedChunk);
  const hexArrWithEvenElements = equalizeLengthOfElements(hexArr, 6);
  return hexArrWithEvenElements.join("");
};

const cipherChunkedCharsArr = (array, key) => {
  const keyCharsArr = textStringToCharsArr(key);
  return array.map((chunk) => cipherChunk(chunk, keyCharsArr));
};

const chunkString = (string, charsPerChunk) => {
  const reg = new RegExp(`.{1,${charsPerChunk}}`, "g");
  return string.match(reg);
};

const chunkedHexArrToChunkedDecArr = (array) => {
  return array.map((array) => convertEachHexElementToDec(array));
};

const divideEachChunk = (array, divisorsArray) => {
  return array.map((element, index) => element / divisorsArray[index]);
};

const decipherChunkedCharsArr = (array, key) => {
  const keyCharsArr = textStringToCharsArr(key);
  return array.map((chunk) => divideEachChunk(chunk, keyCharsArr));
};

const joinChunkedArray = (array) => {
  return [].concat(...array);
};

const decCharsArrtoCharsArr = (array) => {
  return array.map((decChar) => convertCodeToChar(decChar));
};

const encode = (text, key) => {
  const inputCharsArr = textStringToCharsArr(text);
  const chunkedInputCharsArr = chunkArr(inputCharsArr, key.length);
  const cipheredChunkedInputCharsArr = cipherChunkedCharsArr(
    chunkedInputCharsArr,
    key
  );
  const cipheredText = cipheredChunkedInputCharsArr.join("");
  return cipheredText;
};

const decode = (text, key) => {
  const hexCharsArr = chunkString(text, 6);
  const chunkedHexCharsArr = chunkArr(hexCharsArr, key.length);
  const chunkedDecCharsArr = chunkedHexArrToChunkedDecArr(chunkedHexCharsArr);
  const decipheredChunkedDecCharsArr = decipherChunkedCharsArr(
    chunkedDecCharsArr,
    key
  );
  const decCharsArr = joinChunkedArray(decipheredChunkedDecCharsArr);
  const charsArr = decCharsArrtoCharsArr(decCharsArr);
  const string = charsArr.join("");
  return string;
};

export { encode, decode };
