import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ render: renderProp, isAuthenticated, ...rest }) => {
  const Component = renderProp().type;
  const renderProps = renderProp().props;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Component history={props.history} {...renderProps} />;
        } else {
          return <Redirect to={"/"} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
